
import React, {useState, useRef, useEffect} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com'
import BannerImg from '../assets/images/Appointment.webp';
import Footer from "../components/layout/Footer";
import CommonBanner from "../components/CommonBanner";
import { Helmet } from "react-helmet";
import Header from "../components/layout/Header";

const BookAnAppointment = () =>{
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  const [verified, setVarified] = useState(false)
  function onChange(value) {
    console.log("Captcha value:", value);
    setVarified(true);
  }
  const refform = useRef();
  const sendForm = (e) =>{
    e.preventDefault();
    emailjs.sendForm('service_gmail01','template_northshore2',e.target,'2-EU-Dmtl9lxLGg_r').then(res=>{
      console.log(res)
      window.location.href="/thank-you"
  }).catch(err=>{
    console.log(err)
  });
  e.target.reset()
};

    return (
    <>
        <Helmet>
      <title>
      Book an Appointment - North Shore Digestive Medicine
      </title>
    </Helmet>
    <Header/>

    <CommonBanner
    Title="Book an Appointment"
    Image={BannerImg}
    />
    
    <section id="book-appointment" className='pt-5 mt-lg-5'/>
    <section className="container-fluid px-4 px-lg-5">
        <div className="row justify-content-center align-items-center py-md-5 py-4">
        <div className="col-11 col-lg-8">
                <div className="">
                <div className='fs-ss-30 fw-600 lh-sm text-center text-ss-secondary pb-4'>
                  Book An Appointment With Dr. Forohar
                </div>
                <div className="col-12">
                <form ref={refform} onSubmit={sendForm} id="contactForm" method="POST" action="#" className="px-lg-4">
      <div className="form-group d-md-flex">
        <div className="w-50">
        <label className=" font-pri fw-600 pt-4 pb-2" for="name"> First Name* </label>
        <input type="text" className="form-control py-3 bg-light" id="name" name="first_name" placeholder="" required/>
        </div>

        <div className="px-4" />

        <div className="w-50">
        <label className=" font-pri fw-600 pt-4 pb-2" for="name"> Last Name* </label>
        <input type="text" className="form-control py-3 bg-light" id="name" name="last_name" placeholder="" required/>
        </div>
      </div>
      <div className="form-group d-md-flex">
        <div className="w-50">
        <label className=" font-pri fw-600 pt-4 pb-2" for="email"> Email* </label>
        <input type="email" className="form-control py-3 bg-light" id="email" name="email" placeholder="" required/>
        </div>

        <div className="px-4" />

        <div className="w-50">
        <label className=" font-pri fw-600 pt-4 pb-2" for="best_time" > Choose a Procedure* </label>
        <select className="form-select py-3 bg-light pe-4" required aria-label="Default select example" name="procedure">
        <option value="" style={{display:"none"}} > Please select a procedure </option>
        <option value="ESG"> Endoscopic Sleeve Gastroplasty (ESG) </option>
        <option value="Orbera Balloon"> Orbera Balloon </option>
        <option value="Gastric Revisions"> Gastric Revisions </option>
        

        <option value="Colonoscopy"> Colonoscopy </option>
        <option value="EGD"> Upper Endoscopy (EGD) </option>
        <option value="ERCP"> Endoscopic Retrograde Cholangiopancreatography (ERCP) </option>
        <option value="CapsoVision Endoscopy"> CapsoVision Endoscopy </option>
        <option value="PEG"> Percutaneous Endoscopic Gastrostomy (PEG) </option>
        <option value="Radio Frequency Ablation Therapy"> Radio Frequency Ablation Therapy </option>
        
        </select>
        </div>
      </div>
      <div className="form-group d-md-flex">
        <div className="w-50">
        <label className=" font-pri fw-600 pt-4 pb-2" for="name"> Phone Number* </label>
        <input type="number" className="form-control py-3 bg-light" id="name" name="phone_number" placeholder="" required/>
        </div>

        <div className="px-4" />

        <div className="w-50">
        <label className=" font-pri fw-600 pt-4 pb-2" for="name"> Postal Code* </label>
        <input type="number" className="form-control py-3 bg-light" id="name" name="postal_code" placeholder="" required/>
        </div>
      </div>
      <div className="form-group d-md-flex">
        <div className="w-50">
        <label className=" font-pri fw-600 pt-4 pb-2" for="name" required> Select Appointment Date* </label>
        <input type="date" className="form-control py-3 bg-light" id="name" name="appointment_date" placeholder="" required/>
        </div>

        <div className="px-4" />

        <div className="w-50">
        <label className=" font-pri fw-600 pt-4 pb-2" for="best_time" > Best time of day to reach you* </label>
        <select className="form-select py-3 bg-light" aria-label="Default select example" name="best_time">
        <option value="Morning"> Morning </option>
        <option value="Afternoon"> Afternoon </option>
        <option value="Evening"> Evening </option>
        </select>
        </div>
      </div>
        <div className="form-group">
        <label className=" font-pri fw-600 pt-4 pb-2" for="message"> Message* </label>
        <textarea className="col-pri form-control py-3 bg-light" id="message" name="message" rows="2" placeholder="" required></textarea>
      </div>
      <div className="pt-4">
      <ReCAPTCHA
          // sitekey="6LfZjx4nAAAAAMnyR_l8ISMXnPJtao1SC1C2joyh"
// For Local Host
sitekey="6LeBksYpAAAAAEARKOI3Xjdb5_aJvbxU5ewKK0EM" 
// <button className='w-100 btn-customization md-btn'>
// Submit
// </button>
    onChange={onChange}/>
      <button className='mt-4 nav-link fw-500 shadow-ss-1 btn-black border-0 rounded-3 border-0 text-white fs-ss-16 px-3 py-1' disabled={!verified}>
            Submit
        </button>
      </div>
    </form>
    </div>
                </div>
            </div>
        </div>
    </section>
        <section className='pt-5 mt-lg-5'/>

    <Footer/>

    </>
    )
}

export default BookAnAppointment;