import React,{useEffect} from 'react'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
// import { Link } from 'react-router-dom'

const Blog = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [blog, setBlog] = React.useState([])
    const [contentLoading, setContentLoading] = React.useState(true)

    useEffect(() => {
        // get last part of url
        const slug = window.location.href.split('/').pop()
        
        // get blog by slug
        axios.get(`${process.env.REACT_APP_API_URL}/blogs/slug/${slug}`).then(res => {
            if(res.data){
                setBlog(res.data)
            }else{
                setBlog([])
            }
        }).catch(err => {
            console.log(err)
        }
        ).finally (() => {
            setContentLoading(false)
        })

    }, [])

    return (
        <>
        <Helmet>
            <title>{blog?.metaTitle}</title>
            <meta name="description" content={blog?.meta_description} />
            <meta name="keywords" content={blog?.meta_keywords} />
            <meta property="og:title" content={blog?.metaTitle} />
            <meta property="og:description" content={blog?.meta_description} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content="Sameena Medical Clinic" />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
        </Helmet>
            <Header />
            <section className="px-3 px-md-5 bg-light-blog text-center py-5">
            <h1 className='lh-zk text-zk-primary px-md-5 py-md-5' style={{fontWeight:"600"}}>
                {blog?.title}
            </h1>
            </section>
            <div className='bg-page' id="website-responsive">
                
                {/* <BannerCategory /> */}
                <section className='pb-4'>
                    <div className='container-fluid'>
                        <div className='row py-5 my-md-5' id="">
                        {contentLoading ?
                            <div className='col-md-12 col-sm-12 text-center'>
                                <Spinner animation="border" variant="dark" />
                            </div>
                            :
                            <>
                            <div className="col-md-7 mx-auto">
                                <div className="">
                                    <div className="text-center shadow mb-4">
                                    <img className='w-100' src={blog?.featured_image} alt="Blog Image" />
                                </div>
                                    <div className='mt-3'>

                                        <div dangerouslySetInnerHTML={{__html: `${blog?.content}`}}></div>
                                    </div>
                                    
                                    <div className='text-zk-primary '>
                                        {/* Uploaded  */}
                                        Uploaded Date: {moment(blog?.created_at).format('MMMM DD, YYYY')}
                                    </div>
                                </div>
                            </div>

                            <div className="col-4 fs-ss-blog fw-600">
                                <div className="col-10 bg-light-blog mx-auto p-5 lh-sm rounded-4 sticky-md-top">
                                READY TO START <br className='' />
                                YOUR JOURNEY? <br className='' />
                                LET'S TALK!

                                <div className="mt-4">
                                <Link to="/book-an-appointment" className='text-decoration-none py-2 fw-600 text-white bg-ss-secondary rounded-5 fs-ss-15 px-3'>
                                Book an Appointment
                                </Link> 
                                </div>
                                </div>

                            </div>
                            </>
                        }
                        </div>
                    </div>
                </section>

            </div>

        <Footer />

        </>
    )
}

export default Blog