import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/education/Banner.webp';
import Img1 from '../assets/images/education/1.webp';
import Img2 from '../assets/images/education/2.webp';
import MiddleBannerImage from '../assets/images/education/MiddleBanner.webp';
import CommonBanner from '../components/CommonBanner';
import { TelephoneFill } from 'react-bootstrap-icons';
import { MetaTags } from 'react-meta-tags';

const Education = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      <MetaTags>
        <title> Education - North Shore Digestive Medicine </title> 
      </MetaTags>
    <Header/>

    <CommonBanner
    Title="Education"
    Image={BannerImg}
    />

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
<div className="row justify-content-center">
    <div className="col-11">
      <div className=''>
      <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
        Education, North Shore Digestive Medicine  
        </h2>
        <div className='pt-4'/>
        <div className='fs-ss-16 fw-400 lh-para text-start'>
        The solution to each patient’s weight management is unique to that patient. At North Shore Digestive Medicine, we don’t believe in cookie-cutter solutions. Some patients may need diet, exercise, and awareness of their eating habits. Some may require a medication-centered approach. Others may benefit from surgical techniques such as Surgical Sleeve or a Gastric Bypass, and some may benefit from Gastric Balloon or ESG. After an in-depth consultation, we will recommend how to proceed with your weight management journey. 
        </div>
        </div>
      </div>
      </div>
</section>

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
<div className="row justify-content-center">
    <div className="col-11">
      <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
      Our Surgical Procedures 
        </h2>
      </div>
      </div>
</section>


<section className='pt-md-5 pt-2'/>

<section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 pb-md-0 pb-3">
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-30 fw-600 lh-sm text-uppercase text-ss-primary'>
            Surgical Sleeve Revision
            </h2>
            <div className='pt-2'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Weight regain occurs most often due to stomach dilation. With Surgical Sleeve Revision, our professional physician re-tightens your stomach to its original post-surgical anatomy. This will lead to weight loss by causing early satiety and reduced oral intake.   
            </div>
          </div>
        </div>
        <div className="col-8 col-md-7 col-lg-4">
        <img className='w-100 px-md-5' src={Img1} alt="" />        
        </div>
      </div>
    </section>

    <section className='mt-4'/>

<section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 pb-md-0 pb-3">
      <div className="col-11 col-lg-4 d-none d-lg-block ">
        <img className='w-100 px-md-5' src={Img2} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-30 fw-600 lh-sm text-uppercase text-ss-primary'>
            Bypass Revision
            </h2>
            <div className='pt-2'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Weight regain often occurs due to (a) dilation of the pouch, (b) dilation of the outlet, or (c) dilation of the pouch and outlet. Transoral outlet reduction (ToRe) is an endoscopic procedure that reduces the size of the pouch (if needed), the outlet, or the pouch and outlet (depending on each patient’s need) to the original post-bypass anatomy. This will help you feel full sooner and lose weight.  
            </div>
          </div>
        </div>
        <div className="col-8 col-md-7 col-lg-4 d-block d-lg-none">
        <img className='w-100' src={Img2} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-3'/>
    
    <section id='website-responsive' className={`container-fluid bg-middle-banner2 py-5`} style={{backgroundImage:`url(${MiddleBannerImage})`}}>
      <div className="row justify-content-center">
        <div className="col-11 col-lg-6 py-md-5 text-center text-lg-start">
          <div className="" />
          <h2 className='fw-700 text-white fs-ss-middle-banner2 text-uppercase lh-sm'>
            Ready to Start <br/>Your Journey? <br/>Let's Talk!
          </h2>
          <div id='btn' className="pt-4 pt-lg-5">
          <a href="tel:+16317245300" className='text-decoration-none py-2 fw-500 btn-middle-banner2 rounded-5 fs-ss-18 px-4 border-white border-1 border'>
         <TelephoneFill className='me-2'/> 631-724-5300
          </a>          
          </div>
        </div>
        <div className="col-4 col-lg-4"/>        
      </div>
    </section>

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
<div className="row justify-content-center">
    <div className="col-11">
      <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
      What do we offer? 
        </h2>
      </div>
      
      <div className="col-11">
        
      <div className="pt-2 pt-lg-5 text-start">
      <h3 className='fs-ss-28 fw-600 lh-sm text-ss-primary'>
      Endoscopic Sleeve Gastroplasty (ESG)  
      </h3>
        <div className='pt-2'/>
        <div className='fs-ss-16 fw-400 lh-para text-start'>
        At North Shore Digestive Medicine, we offer Endoscopic Sleeve Gastroplasty (ESG), a procedure with a practical approach to weight loss without surgery. Our focus is on providing personalized care and phenomenal support to each patient on their weight loss journey.   
        </div>
        </div>

        <div className="pt-4 pt-lg-5 text-start">
      <h3 className='fs-ss-28 fw-600 lh-sm text-ss-primary'>
      Orbera Balloon 
      </h3>
        <div className='pt-2'/>
        <div className='fs-ss-16 fw-400 lh-para text-start'>
        At our clinic, we understand that every individual's weight loss journey is unique. We offer personalized consultations and treatment plans to ensure that the Orbera Balloon procedure meets your needs and preferences. We are here to guide you every step of the way, providing support and encouragement to help you succeed in reaching your wellness objectives. 
        </div>
        </div>

        <div className="pt-4 pt-lg-5 text-start">
      <h3 className='fs-ss-28 fw-600 lh-sm text-ss-primary'>
      Revision of prior Gastric Surgery  
      </h3>
        <div className='pt-2'/>
        <div className='fs-ss-16 fw-400 lh-para text-start'>
        Gastric Revisions is a non-surgical weight loss solution that could help you achieve a healthier lifestyle. This procedure may be ideal for those with conditions such as fatty liver, high cholesterol, a BMI between 30 and 50, or type 2 diabetes, or those who have struggled with traditional weight loss methods. 
        </div>
        </div>
        </div>
        </div>
        </section>
        
        <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default Education;
