import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/orbera/BannerImg.webp';
import Img1 from '../assets/images/orbera/Img1.webp';
import Img2 from '../assets/images/orbera/Img2.webp';
import MiddleBannerImage from '../assets/images/orbera/MiddleBg.webp';
import MiddleBanner2 from '../components/common/MiddleBanner2';
import Video1 from '../assets/images/home/Video1.mp4';
import CommonBanner from '../components/CommonBanner';
import FAQsSection from '../components/FAQsSection';
import { TelephoneFill } from 'react-bootstrap-icons';
import { MetaTags } from 'react-meta-tags';

const OrberaBalloon = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      <MetaTags>
        <title> Orbera Balloon - Safe & Effective Weight Loss Long Island, NY </title> 
        <meta name="description" content="Orbera Balloon - the solution you need to achieve your ideal weight and reclaim confidence in Long Island, NY. Get Appointment Now !" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Orbera Balloon - Safe & Effective Weight Loss Long Island, NY" />
        <meta property="og:description" content="Orbera Balloon - the solution you need to achieve your ideal weight and reclaim confidence in Long Island, NY. Get Appointment Now !" />
        <meta property="og:url" content="https://northshoredigestivemedicinepc.com/" />
        <meta property="og:site_name" content="NSDM" />
        <meta property="og:image" content="https://northshoredigestivemedicinepc.com/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://northshoredigestivemedicinepc.com/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Orbera Balloon - the solution you need to achieve your ideal weight and reclaim confidence in Long Island, NY. Get Appointment Now !" />
        <meta name="twitter:title" content="Orbera Balloon - Safe & Effective Weight Loss Long Island, NY" />
        <meta name="twitter:image" content="https://northshoredigestivemedicinepc.com/og.webp"/>
      </MetaTags>
    <Header/>

    <CommonBanner
    Title="Orbera Gastric Balloon"
    Image={BannerImg}
    />

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary bg-blue-pattern2 text-lg-center">
<div className="row justify-content-center">
    <div className="col-11">
      <div className=''>
      <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
      North Shore ORBERA BALLOON
        </h2>
        <div className='pt-4'/>
        <div className='fs-ss-16 fw-400 lh-para text-start'>
          Are you struggling to shed those extra pounds, no matter what you try? If traditional weight loss methods have frustrated you, the Orbera Balloon could be the solution you've been searching for. This non-surgical approach to weight loss offers hope for those who have struggled to see results with diet and exercise alone. Find out how the Orbera Balloon can help you achieve your weight loss goals and regain control of your health.
          <div className='py-2'/> 
          At our clinic, we understand that every individual's weight loss journey is unique. We offer personalized consultations and treatment plans to ensure that the Orbera Balloon procedure meets your needs and preferences. We are here to guide you every step of the way, providing support and encouragement to help you succeed in reaching your wellness objectives.
        </div>
        </div>
      </div>
      </div>
      <div className="row justify-content-center text-center pt-5 mt-lg-4">
        <div className="col-11 col-lg-7 bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black">
          <video className='w-100 rounded-3' src={Video1} autoPlay={true} loop="true" muted controls />
        </div>
</div>
</section>

<section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4">
        <div className="col-11 col-lg-4 d-none d-lg-block ">
        <img className='w-100' src={Img1} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Transform Your Body,
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Transform Your Life </h3>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            The Orbera balloon is a non-surgical and revolutionary program designed to assist individuals in achieving significant weight loss through a combination of a gastric balloon, personalized coaching, and ongoing support.  It offers a safe and effective alternative for those seeking to improve their health and wellness by shedding excess pounds.   
            </div>
          </div>
        </div>
        <div className="col-10 col-md-7 col-lg-4 d-block d-lg-none">
        <img className='w-100' src={Img1} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-3'/>
    
    <section id='website-responsive' className={`container-fluid bg-middle-banner2 py-5`} style={{backgroundImage:`url(${MiddleBannerImage})`}}>
      <div className="row justify-content-center pt-md-5">
        <div className="col-11 col-lg-6 py-md-5 text-center text-lg-start">
          <div className="pt-md-3" />
          <h2 className='fw-700 text-white fs-ss-middle-banner2 text-uppercase lh-sm'>
            Ready to Start <br/>Your Journey? <br/>Let's Talk!
          </h2>
          <div id='btn' className="pt-4 pt-lg-5">
          <a href="tel:+16317245300" className='text-decoration-none py-2 fw-500 btn-middle-banner2 rounded-5 fs-ss-18 px-4 border-white border-1 border'>
         <TelephoneFill className='me-2'/> 631-724-5300
          </a>          
          </div>
        </div>
        <div className="col-4 col-lg-4"/>        
      </div>
    </section>

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQsSection
          Title="How much weight can I lose after the Orbera procedure?"
          Para={<>
          The Orbera Integrated Weight Loss procedure has been shown to facilitate significant weight loss, with patients typically experiencing a reduction in total body weight ranging from 10%.  However, individual weight loss outcomes may vary depending on numerous factors, including adherence to the program, lifestyle modifications, and medical history.   
          <div className='py-2'/> 
          The program’s effectiveness is enhanced by the combination of the gastric balloon, which creates a feeling of fullness, and personalized coaching, which helps patients make sustainable changes to their diet and lifestyle habits. 
          </>}
          />

          <FAQsSection
          Title="How long will the recovery from the Orbera procedure take? "
          Para={<>
          The recovery period following the Orbera procedure is relatively brief.  Most patients can restart their normal activities within 1-3 days after the gastric balloon placement.  While some individuals may experience mild side effects such as nausea, discomfort, or bloating during the initial days following the procedure, these symptoms typically subside quickly.   
          <div className='py-2'/> 
          Patients are advised to follow post-procedure guidelines provided by experts to ensure a smooth recovery process and optimize the benefits of the Orbera system.   
          </>}
          />

          <FAQsSection
          Title="What happens after Orbera is removed?"
          Para="After the Orbera balloon has been in place for six months, it is removed in a minimally invasive procedure performed by your physician.  Following the gastric balloon removal, patients continue to receive ongoing support and guidance from our North Shore Digestive Medicine team."
          />
        </div>
      </div>
    </section>

<section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 pb-md-0 pb-3">
      <div className="col-11 col-lg-4 d-none d-lg-block ">
        <img className='w-100' src={Img2} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Orbera Ballon
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2">  at North Shore Digestive Medicine </h3>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            We assist patients in transitioning to a maintenance phase, during which the focus shifts to sustaining weight loss results and adopting healthy lifestyle habits for long-term success.  Our comprehensive approach includes continued coaching, education, and encouragement to empower patients to maintain their weight loss achievements and enjoy improved health and well-being beyond the duration of the Orbera program.   
            </div>
          </div>
        </div>
        <div className="col-10 col-md-7 col-lg-4 d-block d-lg-none">
        <img className='w-100' src={Img2} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default OrberaBalloon;
