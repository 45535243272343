import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/about-us/Banner.webp';
import Img1 from '../assets/images/about-us/Img1.webp';
import MiddleBannerImage from '../assets/images/about-us/MiddleBg.webp';
import Icon1 from '../assets/images/about-us/1.webp';
import Icon2 from '../assets/images/about-us/2.webp';
import Icon3 from '../assets/images/about-us/3.webp';
import CommonBanner from '../components/CommonBanner';
import FAQsSection from '../components/FAQsSection';
import { TelephoneFill } from 'react-bootstrap-icons';
import Bullets from '../components/common/Bullets';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      About Us - North Shore Digestive Medicine
      </title>
    </Helmet>
    <Header/>

    <CommonBanner
    Title="About Us"
    Image={BannerImg}
    />

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
<div className="row justify-content-center">
    <div className="col-11">
      <div className=''>
      <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
        ABOUT NORTH SHORE
        </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Digestive Medicine & Non Surgical Weight Loss Program
            </h3>
        <div className='pt-4'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        North Shore Digestive Medicine, located in Smithtown, New York, is a pillar of community-oriented healthcare, emphasizing compassion, integrity, and innovation. With a rich history spanning over 20 years, we are dedicated to providing patient-focused care that celebrates diversity and strives for ongoing enhancement.
        </div>
        </div>
      </div>
      </div>
</section>

<section className='pt-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary py-md-5 text-center text-lg-start col-11 col-lg-12" style={{borderRadius:"40px"}}>
      <div className="row justify-content-evenly align-items-center gy-4">
        <div className="col-11 col-md-3 ps-md-5">
          <div className=''>
            <h2 className='fs-ss-68 fw-600 fw-600 lh-sm text-uppercase'>
              Core <br /> Values
            </h2>
            <div className='pt-2'/>
            <h3 className='fs-ss-20 fw-500 lh-para text-ss-primary'>
            North Shore Core Values
            </h3>
          </div>
        </div>

        <div className="col-12 row col-md-9 justify-content-center text-center gy-4 gx-5">
        <div className='col-8 col-lg-3 lh-sm mt-4'>
            <div className="bg-light py-4 rounded-4 shadow-ss-1 px-md-0 px-4">
            <img className='w-60 my-md-4' src={Icon1} alt="" />
            </div>
            <div className='pt-md-4 pt-3'/>
            <h4 className='fs-ss-22 fw-600'>
              Patient Safety
            </h4>
          </div>
          <div className='col-8 col-lg-3 lh-sm mt-4'>
            <div className="bg-light py-4 rounded-4 shadow-ss-1 px-md-0 px-4">
            <img className='w-60 my-md-4' src={Icon2} alt="" />
            </div>
            <div className='pt-md-4 pt-3'/>
            <h4 className='fs-ss-22 fw-600'>
              Provider Excellence
            </h4>
          </div>
          <div className='col-8 col-lg-3 lh-sm mt-4'>
            <div className="bg-light py-4 rounded-4 shadow-ss-1 px-md-0 px-4">
            <img className='w-60 my-md-4' src={Icon3} alt="" />
            </div>
            <div className='pt-md-4 pt-3'/>
            <h4 className='fs-ss-22 fw-600'>
              Fight for Every Pound
            </h4>
          </div>
        </div>

      </div>
    </section>
    <section className='pt-5'/>
    
    <section id='website-responsive' className="container-fluid bg-middle-banner-plain py-5" style={{backgroundImage:`url(${MiddleBannerImage})`}}>
      <div className="row justify-content-center py-lg-5">
        <div className="col-lg-6"/>        
        <div className="col-11 col-lg-5 ps-md-4 text-center text-md-start" >
          <h2 className='fw-700 text-white fs-ss-middle-banner2 text-uppercase lh-sm'>
            Discover a Healthier You at North Shore
          </h2>
          <div id='btn' className="pt-3 pt-lg-5">
          <a href="tel:+16317245300" className='text-decoration-none py-2 fw-500 btn-middle-banner2 rounded-5 fs-ss-18 px-4 border-white border-1 border'>
         <TelephoneFill className='me-2'/> 631-724-5300
          </a>          
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
<div className="row justify-content-center">
    <div className="col-11">
      <div className=''>
      <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
        Our Mission
        </h2>
        <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2">
          Creating Your Healthier Future With Our Support 
          </h3>
        <div className='pt-4'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        At North Shore Digestive Medicine, our mission is simple yet profound: to empower our patients to reclaim their health and vitality through compassionate care, innovative treatments, and personalized attention. We believe that everyone deserves to live their life to the fullest, free from the burden of digestive disorders and excess weight. That's why we are dedicated to providing the highest quality care, tailored to the unique needs of each individual who walks through our doors.
        
        With a focus on non-surgical weight loss and digestive medicine procedures, we strive to offer safe, effective solutions that not only address the symptoms but also target the underlying causes of our patients' health challenges. Our commitment to excellence, integrity, and patient satisfaction drives everything we do, and we are honored to be a trusted partner in our patients' journey to better health.
        </div>
        </div>
      </div>
      </div>
      </section>
      
      <section className='pt-5 mt-lg-5'/>
      
      <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4">
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Why Choose
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> North Shore Digestive Medicine?
            </h3>
            <div className='pt-4'/>
            <div className='fs-ss-20 fw-500 lh-para'>
            <Bullets
            Title="Experienced Professionals "
            />
            <Bullets
            Title="Personalized Care "
            />
            <Bullets
            Title="Community Engagement "
            />
            <Bullets
            Title="Advanced Facilities "
            />
            <Bullets
            Title="Non-surgical Weight Loss procedures"
            />
            </div>
          </div>
        </div>
        <div className="col-10 col-md-7 col-lg-4">
        <img className='w-100' src={Img1} alt="" />        
        </div>
      </div>
    </section>

      <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default AboutUs;
