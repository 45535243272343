import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Img1 from '../assets/images/esg-landing-page/Img1.webp';
import Img2 from '../assets/images/esg-landing-page/Img2.webp';
import Img3 from '../assets/images/esg-landing-page/Img3.webp';
import Img4 from '../assets/images/esg-landing-page/Img4.webp';
import Image1 from '../assets/images/esg-landing-page/Icon1.webp';
import Image2 from '../assets/images/esg-landing-page/Icon2.webp';
import Image3 from '../assets/images/esg-landing-page/Icon3.webp';
import BgDollar from '../assets/images/esg-landing-page/BgDollar.png';
import MiddleBannerImage from '../assets/images/esg-landing-page/MiddleBg.webp';
import Video1 from '../assets/images/home/Video2.mp4';
import { Link } from 'react-router-dom';
import Icon1 from '../assets/images/esg-landing-page/1.webp';
import Icon2 from '../assets/images/esg-landing-page/2.webp';
import Icon3 from '../assets/images/esg-landing-page/3.webp';
import Icon4 from '../assets/images/esg-landing-page/4.webp';
import Icon5 from '../assets/images/esg-landing-page/5.webp';
import Icon6 from '../assets/images/esg-landing-page/6.webp';
import Icon7 from '../assets/images/esg-landing-page/7.webp';
import Icon8 from '../assets/images/esg-landing-page/8.webp';
import FAQsSection from '../components/FAQsSection';
import BenefitsofESG from '../components/common/BenefitsofESG';
import { CircleFill, GeoAltFill, TelephoneFill } from 'react-bootstrap-icons';
import TopHeader from '../components/layout/TopHeader';
import Bullets from '../components/common/Bullets';
import HeaderLogo from '../assets/images/home/HeaderLogo.webp';
import BMI2 from '../components/common/BMI2';
import Footer2 from '../components/layout/Footer2';

const ESGLandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
    <meta name="robots" content="noindex"/>
      <title>
      ESG Landing Page - North Shore Digestive Medicine
      </title>
    </Helmet>
        <section className="container-fluid px-0">
    <section className="container-fluid bg-light d-flex px-lg-5 align-items-center">
      <div className="py-2 text-center">
      <Link className="" to="/" >
        <img src={HeaderLogo} alt="Logo" className="header-logo" />
      </Link>
      </div>

      <div className="ms-lg-auto me-4 d-none d-md-block">
        {/* <span className="">
          <GeoAltFill className='me-2'/>
          <a href="https://maps.app.goo.gl/FEjmEcx22Gw3xsvF6" target="_blank" className='fs-ss-14 text-ss-secondary fw-500 text-decoration-none'>
            50 Route 111 Suite 302 Smithtown, New York 11787
          </a>
        </span> */}
      </div>

      <div className="d-none d-md-block">
      <a href="tel:+16319978135" target="_blank" className='text-center nav-link shadow-ss-1 btn-ss-primary border-0 rounded-5 border-0 text-white fs-ss-links px-md-4 px-3 py-2'>
      <TelephoneFill className='me-2'/> (631) 997-8135
      </a>
      </div>

      <div className="d-block d-md-none ms-auto me-3 text-ss-primary">
      <a href="tel:+16319978135" target="_blank" className='text-center nav-link fw-600 fs-ss-links'>
      (631) 997-8135
      </a>
      </div>
    </section>

    
    <section className="container-fluid text-center text-lg-start text-avenir ">
      <div className="row justify-content-center align-items-center py-5 gy-5">
        <div className="col-11 col-lg-6 ps-lg-5 ms-lg-5">
          <div className="text-ss-primary fw-700 fs-ss-title2-landing-pages text-ss-secondary pb-3 pb-md-0">
          ACHIEVE SIGNIFICANT <br />
          <span className='text-ss-primary'> WEIGHT LOSS! </span> </div>
          <div className='fs-ss-sub-title2-landing-pages fw-400 lh-para pt-md-2'>
          With Non-Surgical Endoscopic Sleeve Gastroplasty <span className='text-ss-primary'> (ESG) </span> Procedure
          </div>

          <div id='btn' className="pt-lg-5 pt-4 d-none d-md-block ">
          <Link to="/book-an-appointment" className='fs-ss-18 text-decoration-none py-md-2 py-1 fw-500 btn-orange border-0 text-ss-secondary rounded-5 px-3'>
          Request a Consultation
          </Link>          
          </div>


          <div id='btn-landingpage' className="pt-lg-5 pt-4 d-block d-md-none">
          <a href="tel:(631) 997-8135" className='fs-ss-18 text-decoration-none py-md-2 py-1 fw-600 btn-orange border-0 text-ss-secondary rounded-5 px-3'>
          Book a Consultation Now
          </a>          
          </div>
        </div>
        <div className="col-11 col-md-8 col-lg-5 px-0">
        <img className='w-100' src={Img1} alt="" />
        </div>
      </div>
    </section>

<section className='pt-md-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary text-lg-center bg-mobile pt-4 pt-md-0">
      <div className="row justify-content-center">
        <div className="col-11">
          <div id='mobile-font' className=''>
            <div className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Benefits of ESG Procedure <br className='' /> 
            <div className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Revolutionize your weight loss journey with minimal downtime </div>
            </div>
          </div>
        </div>
        <div className='pt-5'/>
        <div className="row justify-content-center">
        <div className="col-12 col-md-5">
          {/* <BenefitsofESG
          Image={Icon1}
          Title="Minimally invasive procedure"
          Para="Performed through a small incision using a thin, lighted scope. "
          />
           */}
          <BenefitsofESG
          Image={Icon2}
          Title=" Non-surgical"
          Para="Avoids traditional surgical incisions and associated recovery time."
          />

          {/* <BenefitsofESG
          Image={Icon3}
          Title="Improved hormone regulation"
          Para="May help regulate hormones that influence hunger and satiety."
          /> */}
          
          <BenefitsofESG
          Image={Icon4}
          Title="Outpatient procedure"
          Para="May allow you to go home the same clay after the procedure."
          /> 
          
          <BenefitsofESG
          Image={Icon5}
          Title="Reduces stomach size"
          Para="A portion of the stomach is removed, creating a sleeve shape and limiting food intake."
          />   
          </div>
          <div className="col-1 d-none d-md-block" />
          <div className="col-12 col-md-5">
          
          <BenefitsofESG
          Image={Icon6}
          Title="18-20% total body weight loss  "
          Para="Up to 20% of body weight loss may be achievable."
          />

          <BenefitsofESG
          Image={Icon7}
          Title="Reduced hunger"
          Para="Feeling full sooner after meals can help manage calorie intake."
          />
          
          <BenefitsofESG
          Image={Icon8}
          Title="Faster recovery"
          Para="Typically associated with a shorter recovery time compared to traditional surgery."
          />

          </div>
        </div>
      </div>
    </section>
    
    <section className='mt-lg-5'/>
    
    <section id='website-responsive' className="container-fluidpy-5 bg-blue py-5">
    <section className="container-lg text-ss-secondary py-lg-4">
    <div className="row justify-content-center align-items-center gy-5 text-center text-md-start">
    <div className="col-11 col-lg-6">
          <div id='landing-page-font-primary' className=''>
            <div className='fs-ss-40 fw-600 lh-sm text-white'>
              <div className='ls-landing' > Accessible Care    </div>
              <div className='fs-ss-56 text-uppercase text-ss-secondary fw-700 ls-landing1' > Manageable Payments </div>
              <div className='fs-ss-28 fw-600 lh-sm text-white ls-landing2' > Get Started Today  </div>
            </div>
            <div className='pt-lg-5'/>
            <div className="d-none d-md-block">
          <a href="tel:+16319978135" className='text-decoration-none py-md-2 py-1 fw-500 btn-dark-transparent rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
          <TelephoneFill className='me-2'/> (631) 997-8135
          </a>          
          </div>
          </div>
          </div>

          <div className="col-10 col-lg-5 bg-dollar-position-parent">
          <img className='w-100' src={BgDollar} alt="" />        
            <div className="col-md-8 fs-ss-22 bg-dollar-position-child fw-600">
            <div className="fs-ss-20">
              Payment starts as low as 
              </div>
              <div className="fs-ss-64">
              $297* <span className='fs-ss-16'> per month </span>

              </div>            
              </div>
          </div>
          <div id='landing-page-font-primary' className="d-block d-md-none">
          <div className='fs-ss-24 fw-600 lh-sm text-white pb-3' > Questions? Call Now! </div>
          <a href="tel:+16319978135" className='text-decoration-none py-md-2 py-1 fw-500 btn-dark-transparent rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
          <TelephoneFill className='me-2'/> (631) 997-8135
          </a>          
          </div>
          </div>   
    </section>
    </section>

    <section className='pt-md-5 mt-lg-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary text-md-center bg-blue-pattern bg-mobile py-5 py-md-0">
      <div className="row justify-content-evenly align-items-center gy-4 ">
        <div className="col-11 col-lg-8">
        <div id='mobile-font' className=''>
          <div className='fs-ss-32 fw-600 lh-sm text-uppercase'>
          How ESG procedure Works? <br className='' /> 
            <div className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Avoid Surgery with Our Non-Invasive Solutions </div>
            </div>
            <div className='pt-md-5 pt-4'/>
            <div className="row justify-content-center text-center">
            <div className="col-11 bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black">
              <video className='w-100 rounded-3' src={Video1} autoPlay={true} loop="true" muted controls />
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-md-5 pt-4 mt-4 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 ">
        <div className="col-11 col-lg-6">
        <div id='mobile-font' className=''>
          <div className='fs-ss-32 fw-600 lh-sm text-uppercase'>
          Why North Shore Digestive Medicine?  <br className='' /> 
            <div className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Competent Compassionate Care </div>
            </div>
            <div className='pt-4'/>
            <div className='fs-ss-18 fw-500 lh-para'>
            <Bullets
            Title="Most experienced ESG practice in Long Island"
            />
            <Bullets
            Title="One year of follow up with Dr. Forohar"
            />   
            <Bullets
            Title="One year of free consultation with a bariatric dietitian or nutritionist"
            />
            </div>
          </div>
        </div>
        <div className="col-9 col-md-5">
        <img className='w-100 px-lg-5' src={Img2} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-md-2 pt-4'/>

    <section id='website-responsive' className="container-fluid bg-middle-banner2 py-5" style={{backgroundImage:`url(${MiddleBannerImage})`}}>
      <div className="row justify-content-center py-lg-5 mt-xxl-5 text-center text-md-start">
        <div className="col-lg-5"/>        
        <div id='landing-page-font'  className="col-11 col-lg-5 pt-lg-5 mt-lg-5 ps-lg-5">
          <div className='fw-700 text-orange fs-ss-middle-banner2 text-uppercase lh-sm'>
          Discover a Healthier You at North Shore
          </div>
          <div id='btn-esg' className="pt-3 pt-lg-5">
          <a href="tel:+16319978135" className='text-decoration-none py-md-2 py-1 fw-500 btn-dark-transparent rounded-5 fs-ss-16 px-4 border-dark border-1 border'>
          (631) 997-8135
          </a>          
          </div>
        </div>
      </div>
    </section>
    <section className='pt-md-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary bg-mobile py-4 pb-5">
      <div className="row justify-content-evenly align-items-center gy-4 ">
      <div className="col-10 col-md-5 d-none d-md-block border border-dark bg-primary rounded-2 py-4 px-5">
      <div className="py-md-2 px-md-5 text-center">
      <BMI2/>
      </div>
      </div>
        <div className="col-11 col-lg-5">
        <div id='mobile-font' className='pt-md-0 pt-4'>
          <div className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Am I a candidate for ESG Procedure? <br className='' /> 
            </div>
            <div className='pt-4'/>

            <div className='fs-ss-17 fw-400 lh-para'>
            Discover if you are suited for our Endoscopic Sleeve Gastroplasty (ESG), a non-surgical approach to weight loss that could be your step toward a healthier lifestyle. You might be a good candidate for ESG if you have: 
            </div>
            <div className='pt-4'/>

            <div className='fs-ss-18 fw-500 lh-para'>

            <div className='fs-ss-18 fw-500 lh-para'>
            <Bullets
            Title="Fatty liver"
            /> 
            <Bullets
            Title="High cholesterol level"
            />
            <Bullets
            Title="BMI between 30 and 50"
            />  
            <Bullets
            Title="Type 2 Diabetes"
            /> 
            <Bullets
            Title="Struggled with traditional weight loss methods"
            />             

            </div>   
            </div>
          </div>
        </div>
        <div className="col-10 col-lg-5 d-block d-md-none border border-dark bg-primary rounded-2 py-4 px-5">
        <div className="py-md-2 px-md-5 text-center">
          <BMI2/>
        </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary bg-commas2">
    <div className="row justify-content-evenly align-items-center">
    <div id='mobile-font-2' className='text-md-center text-start'>
    <div className='col-11 col-lg-12 fs-ss-32 fw-600 lh-sm text-uppercase ps-3'>
    Real Weight Loss Success Story 
    <div className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Avoid Surgery with Our Non-Invasive Solutions </div>
    </div>
      </div>
      </div>
      <div className="row justify-content-evenly align-items-start gy-5 pt-lg-5 pt-4">
      <div className="col-11 col-lg-4 d-none d-lg-block ">
        <img className='w-100 px-lg-5' src={Img3} alt="" />        
        </div>
        <div id='font-testimonials' className="col-11 col-lg-6">
            <div className='fs-ss-16 fw-400 lh-para2 ltsp'>
            <span className='fs-ss-30'>"</span>
            As always, I had a great experience at Dr. Forohar's office. The staff is super friendly and knowledgeable, from the front desk staff to Dr. Forohar. My husband and I have been visiting Dr. Forohar for nearly 20 years and would 100% recommend his office. Keep up the amazing job you all do. Thank you for the Apollo ESG done in October. 
            <div className="py-2"/>
            I'm 50 lbs lighter and halfway to my weight loss goal. For anyone looking to half a non-surgical weight loss procedure, I highly recommend the Apollo ESG. Here are some before-and-after pictures. I'm only halfway there, but there's already a huge difference. Dr. Forohar is an excellent gastroenterologist and very caring before the probate, with you every step of the way.  <span className='fs-ss-30'> " </span>
          </div>
        </div>
        <div className="col-10 col-md-7 col-lg-4 d-block d-lg-none">
        <img className='w-100' src={Img3} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-fluid bg-light py-5">
      <div className="row justify-content-center align-items-center gy-5">      
        <div className="col-11 col-lg-5">
        <div className='fs-ss-56 fw-600 lh-sm text-uppercase'>
        Dr. Forohar, <span className='text-ss-primary'> M.D </span> 
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-18 fw-500 lh-para'>
            Farzad Forohar, M.D. is a board certified gastroenterologist, practicing in Smithtown, New York since 1999.
            </div>
            <div className="pt-md-3 pt-4" />
            <div className="">
            <div className="d-flex text-start align-items-center pb-4">
              <div className="me-4">
                <img src={Image1} alt="" style={{width:"60px"}} />
              </div>
              <div className="">
                <div className='fw-600 fs-ss-30 lh-sm '>
                30+
                </div>
                <div className='fw-500 fs-ss-16'>
                Years of Services
                </div>
                </div>
            </div>
            </div>

            <div className="">
            <div className="d-flex text-start align-items-center pb-4">
              <div className="me-4">
                <img src={Image2} alt="" style={{width:"60px"}} />
              </div>
              <div className="">
                <div className='fw-600 fs-ss-30 lh-sm '>
                40,000+
                </div>
                <div className='fw-500 fs-ss-16'>
                Procedures Performed
                </div>
                </div>
            </div>
            </div>

            <div className="">
            <div className="d-flex text-start align-items-center pb-4">
              <div className="me-4">
                <img src={Image3} alt="" style={{width:"60px"}} />
              </div>
              <div className="">
                <div className='fw-600 fs-ss-24 lh-sm '>
                TOO MANY TO COUNT
                </div>
                <div className='fw-500 fs-ss-16'>
                Happy Clients
                </div>
                </div>
            </div>
            </div>
            <div className="pt-4">
          <Link to="/our-team" className='text-decoration-none py-md-2 py-1 fw-500 btn-transparent-dark text-ss-secondary rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
            Learn More About Dr. Forohar
          </Link>          
          </div>
        </div>
        <div className="col-11 col-md-8 col-lg-5 text-center"> 
        <img className='w-75' src={Img4} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQsSection
          Title="What is Endoscopic Sleeve Gastroplasty (ESG)?"
          Para={<>
          North Shore Digestive Medicine offers Endoscopic Sleeve Gastroplasty (ESG), an advanced, minimally invasive technique for weight loss.  Without requiring any surgical incisions, the stomach is reduced in size by 60-75% using an endoscopic suturing device.   
          <div className='py-2'/> 
          The stomach is sutured during the procedure to form a smaller, tube-shaped pouch.  This lowers the stomach’s capacity to store food, which results in earlier sensations of fullness and less food consumption.   
          </>}
          />
          
          <FAQsSection
          Title="What’s the name of the device inserted through the mouth to stitch and shrink the stomach for weight loss? "
          Para={<>
          Overstitch<span className='fs-ss-22'>®</span> is a small suturing device attached to the end of an endoscope and then passes to the stomach under general anesthesia. Our specifically trained physician then sews your stomach into a small shape.   
          
          <div className='fw-600 py-2'> Your Possibility of Losing Weight with ESG </div>
          
          Unlike conventional bariatric procedures, the digestive tract is not rerouted or any portion of the stomach removed during an ESG.  To ensure that patients are completely informed before making decisions, our knowledgeable North Shore Digestive Medicine staff offers thorough information on the ESG procedure, including its advantages, risks, and predicted outcome of an average total body weight loss of 18-20%.     
          </>}
          />

          <FAQsSection
          Title="How does the smaller stomach size in the ESG procedure contribute to portion control during meals? "
          Para="The ESG procedure reduces the stomach size and length.  This smaller stomach acts as portion control, limiting food intake during meals.  Additionally, the new shape of the stomach slows digestion, prolonging the feeling of fullness and promoting weight loss.  The endoscopic sleeve enhances satiety and extends the feeling of satisfaction."
          />

          <FAQsSection
          Title="How long does the ESG procedure take?"
          Para="The Endoscopic Sleeve Gastroplasty (ESG) procedure typically takes 60-90 minutes.  It is performed under general anesthesia; patients can usually return home the same day.  At North Shore Digestive Medicine, our team guides patients through every process step, from pre-procedure preparation to post-procedure care."
          />

          <FAQsSection
          Title="What are the potential risks or side effects of ESG? "
          Para="Like any medical procedure, Endoscopic Sleeve Gastroplasty (ESG) carries risks and potential side effects, even though it is generally considered safe.  These could include bleeding, nausea, vomiting, or momentary discomfort.  Serious problems are uncommon, however."
          />                    
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className="container-fluid text-ss-secondary text-center bg-pink py-md-5 pt-4 pb-5">
      <div className="row justify-content-center py-4">
        <div className="col-11">
          <div className=''>
            <div className='fs-ss-40 fw-600 lh-sm'>
            Start your journey today!
            </div>
            <div className="pt-4 mt-lg-3">
            <Link to="/book-an-appointment" className='text-decoration-none py-2 fw-600 bg-white-hover text-ss-secondary rounded-5 fs-ss-16 px-3'>
            Book an Appointment
          </Link> 
            </div>
          </div>
        </div>
      </div>
    </section>
    </section>

    <Footer2/>
    </>
  );
}

export default ESGLandingPage;
