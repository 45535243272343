import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/weight-loss/BannerImg.webp';
import Img1 from '../assets/images/weight-loss/Img1.webp';
import Img3 from '../assets/images/esg-landing-page/Img3.webp';
import MiddleBannerImage from '../assets/images/weight-loss/MiddleBg.webp';
import MiddleBanner2 from '../components/common/MiddleBanner2';
import CommonBanner from '../components/CommonBanner';
import FAQsSection from '../components/FAQsSection';
import SingleService2 from '../components/common/SingleService2';
import ServIcon1 from '../assets/images/weight-loss/2.webp';
import ServIcon2 from '../assets/images/weight-loss/1.webp';
import ServIcon3 from '../assets/images/weight-loss/3.webp';
import Video1 from '../assets/images/home/Video1.mp4';
import Video2 from '../assets/images/home/Video2.mp4';
import Video3 from '../assets/images/home/Video3.mp4';
import SingleService from '../components/common/SingleService';
import { Link } from 'react-router-dom';
import { TelephoneFill } from 'react-bootstrap-icons';
import Img2 from '../assets/images/esg-landing-page/Img2.webp';
import Bullets from '../components/common/Bullets';
import { MetaTags } from 'react-meta-tags';


const WeightLoss = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      <MetaTags>
        <title> Non-Surgery Weight Loss Procedure: Long Island, NY </title> 
        <meta name="description" content="Find lasting weight loss with our Non Surgery Weight Loss Procedure in Long Island, NY. Get expert guidance and personalized care. Book Today !" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Non-Surgery Weight Loss Procedure: Long Island, NY" />
        <meta property="og:description" content="Find lasting weight loss with our Non Surgery Weight Loss Procedure in Long Island, NY. Get expert guidance and personalized care. Book Today !" />
        <meta property="og:url" content="https://northshoredigestivemedicinepc.com/" />
        <meta property="og:site_name" content="NSDM" />
        <meta property="og:image" content="https://northshoredigestivemedicinepc.com/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://northshoredigestivemedicinepc.com/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Find lasting weight loss with our Non Surgery Weight Loss Procedure in Long Island, NY. Get expert guidance and personalized care. Book Today !" />
        <meta name="twitter:title" content="Non-Surgery Weight Loss Procedure: Long Island, NY" />
        <meta name="twitter:image" content="https://northshoredigestivemedicinepc.com/og.webp"/>
      </MetaTags>
    <Header/>

    <CommonBanner
    Title={<> Non-Surgery <br className="d-none d-md-block"/> Weight Loss Procedure </>}
    Image={BannerImg}
    />

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Trim Your Waistline, </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Skip the Surgery with Our Non-Surgical Procedures </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            At North Shore Digestive Medicine, we specialize in transforming lives through advanced, non-surgical weight loss procedures. We offer innovative procedures and personalized care, and our dedicated team helps you reach your weight loss goals and enhance your overall well-being. 
            </div>
          </div>
        </div>
      </div>

      <div id='weight-loss' className="row justify-content-center text-center pt-4 pt-lg-5 gy-4">
        <SingleService2
        Image={ServIcon1}
        Title="ESG"
        Link_To="/esg-long-island-ny"
        Space2="me-md-3"
        />

        <SingleService2
        Image={ServIcon2}
        Title="Orbera Balloon"
        Link_To="/orbera-balloon-long-island-ny"
        Space2="me-md-3"
        />

        <SingleService2
        Image={ServIcon3}
        Title="Gastric Revisions"
        Link_To="/gastric-revisions-long-island-ny"
        Space2="me-md-3"
        />   
      </div>
    </section>

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 ">
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Endoscopic Sleeve Gastroplasty </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Shrinking Your Stomach & Expanding Your Life </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            This procedure helps reduce stomach size by using an endoscope without incisions. Creating a smaller stomach pouch, ESG helps limit food intake and encourages weight loss. It also offers a safe, quick, and effective alternative to traditional weight loss surgery. In addition, patients typically experience weight loss of 18-20% of their excess body weight within the 1st year after the procedure. 
            </div>
          </div>
        </div>
        <div className="col-10 col-lg-5 ms-lg-auto bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black">
        <video className='w-100 rounded-3' src={Video2} autoPlay={true} loop="true" muted controls />
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 ">
      <div className="col-10 col-lg-5 me-lg-auto bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black d-none d-md-block">
        <video className='w-100 rounded-3' src={Video1} autoPlay={true} loop="true" muted controls />
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
          <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Orbera Balloon  </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Effortless Weight Loss with Endoscopic Style </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            The Orbera Balloon is a minimally invasive procedure by a physician that involves placing a soft, silicone balloon in the stomach to help control portion sizes and promote feelings of fullness. Moreover, with no surgery required, this temporary implant can help you lose weight and provide the support you need to develop healthier eating habits. On average, patients lose 10% of their total body weight within six months of the procedure.  
            </div>
          </div>
        </div>
        <div className="col-10 col-lg-5 ms-lg-auto bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black d-block d-md-none">
        <video className='w-100 rounded-3' src={Video1} autoPlay={true} loop="true" muted controls />
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>
    
    {/* <section id='website-responsive' className="container-fluidpy-5 bg-light py-5">
    <section className="container-lg text-ss-secondary text-lg-center">
    <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
          <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
          Orbera Balloon  </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Effortless Weight Loss with Endoscopic Style </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            The Orbera Balloon is a minimally invasive procedure by a physician that involves placing a soft, silicone balloon in the stomach to help control portion sizes and promote feelings of fullness. Moreover, with no surgery required, this temporary implant can help you lose weight and provide the support you need to develop healthier eating habits. On average, patients lose 10% of their total body weight within six months of the procedure.  
            </h3>
          </div>
          </div>
          <div className="row justify-content-center text-center pt-5">
            <div className="col-11 col-lg-7 bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black">
              <video className='w-100 rounded-3' src={Video2} autoPlay={true} loop="true" muted controls />
            </h3>      
    </section>
    </section> */}

{/* <section id='website-responsive' className="container-fluidpy-5 bg-light py-5">
    <section className="container-lg text-ss-secondary text-lg-center">
    <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <div className='fs-ss-40 fw-600 lh-sm text-uppercase'>
              Non Surgical Weight Loss <br /> clinic in Long Island, NY
            </div>
            <div className="pt-3 pt-md-4">
            <div className='fs-ss-16 fw-400 lh-para'>
            If you’d like to learn more about weight loss without surgery or to find out if you are a fit candidate or not, please contact us!
            </div>
            <div className='pt-3 pt-md-4 pt-lg-5'/>
          <Link to="/book-an-appointment" className='text-decoration-none py-md-2 py-1 fw-500 btn-transparent-dark text-ss-secondary rounded-5 fs-ss-16 px-3 border-dark border-1 border'>
            Book an Appointment
          </Link>          
          </h3>
          </div>
          </div>   
    </section>
    </section> */}
    
    <section id='website-responsive' className={`container-fluid bg-middle-banner2 py-5`} style={{backgroundImage:`url(${MiddleBannerImage})`}}>
      <div className="row justify-content-center text-center text-md-start">
        <div className="col-11 col-lg-6 py-md-5">
          <h2 className='fw-700 text-white fs-ss-middle-banner2 text-uppercase lh-sm'>
            Empowering You to Reach Your Health Goals, Together
          </h2>
          <div id='btn' className="pt-4 pt-lg-5">
          <a href="tel:+16317245300" className='text-decoration-none py-2 fw-500 btn-middle-banner2 rounded-5 fs-ss-18 px-4 border-white border-1 border'>
         <TelephoneFill className='me-2'/> 631-724-5300
          </a>          
          </div>
        </div>
        <div className="col-4 col-lg-4"/>        
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 ">
      <div className="col-10 col-lg-5 me-lg-auto bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black d-none d-lg-block">
        <video className='w-100' src={Video3} autoPlay={true} loop="true" muted controls />
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Gastric Revisions </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Your Non-Surgical Path to Weight Loss Success </h3>
            <div className='pt-3 pt-md-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            If you've undergone any non-surgical procedure elsewhere but have yet to achieve your desired weight goals or have experienced weight regain, North Shore Digestive Medicine offers revision surgery to address these concerns. Our specialized procedures allow you to reach 12-15% total body weight loss. Patients typically experience a swift recovery, often within 1-3 days, with minimal side effects, as these procedures are performed on an outpatient basis, and patients can return home the same day.
            </div>
          </div>
        </div>
        <div className="col-10 col-lg-5 me-lg-auto bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black d-block d-lg-none">
        <video className='w-100 rounded-3' src={Video3} autoPlay={true} loop="true" muted controls />
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-4'/>



    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4 ">
        <div className="col-11 col-lg-6">
          <div className=''>
          <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
          WHY NORTH SHORE DIGESTIVE MEDICINE? </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Non-Surgical Weight Loss Clinic in Long Island, NY  </h3>
            <div className='pt-4'/>
            <div className='fs-ss-18 fw-500 lh-para'>
            <Bullets
            Title="Easily accessible and effective ESG in Long Island "
            />
            <Bullets
            Title="Experienced Professionals & Advanced Facilities"
            />
            <Bullets
            Title="One year of follow up with Dr. Forohar"
            />   
            <Bullets
            Title="One year of free consultation with a bariatric dietitian or nutritionist"
            />
            </div>
          </div>
        </div>
        <div className="col-9 col-md-5">
        <img className='w-100 px-lg-5' src={Img2} alt="" />        
        </div>
      </div>
    </section>



    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
          <div className='text-center'>
          <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
          OUR PAYMENT PLANS </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Your Non-Surgical Path to Weight Loss Success </h3>
          </div>
      <div className="row justify-content-center text-center pt-4 pt-lg-5 gy-4">
      <div className="col-serv3 text-center bg-primary text-decoration-none rounded-4 px-4 py-5 shadow-ss-1 me-lg-3">
      <h4 className=' fw-600 text-center bg-dark border-0 rounded-5 border-0 text-white fs-ss-18 px-2 py-2'>
        ESG
      </h4>
      <div className="fs-ss-16 fw-400 text-white lh-sm pt-4 pb-2">
      Payment starts as low as
      </div>
      <div className="fs-ss-35 fw-600 text-white pb-3">
      $297
      </div>
      <div className="fs-ss-16 fw-500 text-white lh-sm">
      Call us at <br />
      <a href="tel:+16317245300" target='_blank' className='text-decoration-none text-ss-secondary fw-600 px-2 '>
        631-724-5300 <br />
      </a>
      for more details
      </div>
      </div>

      <div className="col-serv3 text-decoration-none text-center bg-primary rounded-4 px-4 py-5 shadow-ss-1 me-lg-3">
      <h4 className=' fw-600 text-center bg-dark border-0 rounded-5 border-0 text-white fs-ss-18 px-2 py-2'>
        Orbera Balloon
      </h4>
      <div className="fs-ss-16 fw-400 text-white lh-sm pt-4 pb-2">
      Payment starts as low as
      </div>
      <div className="fs-ss-35 fw-600 text-white pb-3">
      $191
      </div>
      <div className="fs-ss-16 fw-500 text-white lh-sm">
      Call us at <br />
      <a href="tel:+16317245300" target='_blank' className='text-decoration-none text-ss-secondary fw-600 px-2 '>
        631-724-5300 <br />
      </a>
      for more details      
      </div>
      </div>

      <div className="col-serv3 text-decoration-none text-center bg-primary rounded-4 px-4 py-5 shadow-ss-1">
      <h4 className=' fw-600 text-center bg-dark border-0 rounded-5 border-0 text-white fs-ss-18 px-2 py-2'>
      Gastric Revisions
      </h4>
      <div className="fs-ss-16 fw-400 text-white lh-sm pt-4 pb-2">
      Payment starts as low as
      </div>
      <div className="fs-ss-35 fw-600 text-white pb-3">
      $297
      </div>
      <div className="fs-ss-16 fw-500 text-white lh-sm">
      Call us at <br />
      <a href="tel:+16317245300" target='_blank' className='text-decoration-none text-ss-secondary fw-600 px-2 '>
        631-724-5300 <br />
      </a>
      for more details      
      </div>
      </div>
      </div>
    </section>
    
    <section className='pt-5 mt-lg-5'/>
  
    <section id='website-responsive' className="container-lg text-ss-secondary bg-commas2">
    <div className="row justify-content-evenly align-items-center">
    <div className='col-11 col-lg-10 fs-ss-32 fw-600 lh-sm text-uppercase text-center'>
      <h2 className='fw-600'>
    Real Weight Loss Success Story 
    </h2>
    <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Avoid Surgery with Our Non-Invasive Solutions </h3>
      </div>
      </div>
      <div className="row justify-content-evenly align-items-start gy-5 pt-lg-5 pt-4">
      <div className="col-11 col-lg-4 d-none d-lg-block ">
        <img className='w-100 px-lg-5' src={Img3} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
            <div className='fs-ss-16 fw-400 lh-para2 ltsp'>
            <span className='fs-ss-30'>"</span>As always, a great experience at Dr. Forohar's office. Staff is super friendly and knowledgeable on every aspect, from the front desk staff all the way to Dr. Forohar. My husband and I have been going to Dr. Forohar for close to 20 years now and would 100% recommend his office. Keep up the amazing job you all do. Thank you After having the Apollo ESG done in October I'm 50lbs lighter and halfway to my weight loss goal. For anyone looking to half a non surgical weight loss procedure I highly recommend the Apollo ESG. Here's some before and after pictures, I'm only halfway there but there's already a huge difference. Dr. Forohar is a wonderful gastrointerologist and very caring not only before the probate with you every step of the way. <span className='fs-ss-30'> " </span>
          </div>
        </div>
        <div className="col-10 col-md-7 col-lg-4 d-block d-lg-none">
        <img className='w-100' src={Img3} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-4'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11 pb-md-0 pb-3">
          <FAQsSection
          Title="What types of weight loss procedures in Long Island does North Shore Digestive Medicine offer? "
          Para="We specialize in advanced, non-surgical weight loss procedures such as Endoscopic Sleeve Gastroplasty (ESG) and Orbera Balloon in Long Island, New York. "
          />

          <FAQsSection
          Title="How does Endoscopic Sleeve Gastroplasty (ESG) work for weight loss? "
          Para="ESG reduces stomach size using an endoscope without incisions, limiting food intake, and encouraging weight loss. Furthermore, patients typically achieve a weight loss of 18-20% of their excess body weight within the first year. "
          />

          <FAQsSection
          Title="What is the Orbera Balloon procedure, and what are its benefits? "
          Para="The Orbera Balloon involves placing a soft silicone balloon in the stomach to control portion sizes and promote fullness. Patients usually lose 10% of their body weight within six months without surgery. So, if you are searching for a “Bariatric nutritionist near me in Long Island, New York,” you can contact North Shore Digestive Medicine."
          />

          <FAQsSection
          Title="What are gastric revisions, and how do they help with weight regain after previous surgeries?"
          Para="Gastric revisions at North Shore Digestive Medicine offer non-surgical interventions for patients who have experienced weight regain after gastric bypass or sleeve gastrectomy procedures. These procedures aim for a 12-15% total body weight loss with minimal recovery time. "
          />

          <FAQsSection
          Title="How does North Shore Digestive Medicine support patients in their weight loss journey?"
          Para="We provide endoscopic weight loss procedures, personalized treatment plans, ongoing guidance, and comprehensive support to help patients achieve their weight loss goals and enhance their overall well-being. In addition, with our expertise and dedication, we're committed to assisting patients every step of the way."
          />

        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default WeightLoss;
