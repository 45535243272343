import React from 'react';
import { Link } from "react-router-dom"
import Navbar from 'react-bootstrap/Navbar';
import HeaderLogo from '../../assets/images/home/HeaderLogo.webp';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavBarLink from './NavBarLink';
import NavBarLink2 from './NavBarLink2';
import { useLocation } from "react-router-dom";
import { GeoAltFill, PhoneFill, TelephoneFill } from 'react-bootstrap-icons';

const TopHeader = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
    <section className="container-fluid bg-light d-lg-flex px-lg-5 align-items-center d-none d-lg-block">
      <div className="py-2">
      <Link className="" to="/" >
        <img src={HeaderLogo} alt="Logo" className="header-logo" />
      </Link>
      </div>

      <div className="ms-lg-auto me-4">
        {/* <span className="">
          <TelephoneFill className='me-2'/>
          <a href="tel:+16317245300" target="_blank" className='fs-ss-14 text-ss-secondary fw-500 text-decoration-none'>
            631-724-5300
          </a>
        </span> */}

        {/* <span className="px-lg-3"/>
        
        <span className="">
          <GeoAltFill className='me-2'/>
          <a href="https://maps.app.goo.gl/FEjmEcx22Gw3xsvF6" target="_blank" className='fs-ss-14 text-ss-secondary fw-500 text-decoration-none'>
            50 Route 111 Suite 302 Smithtown, New York 11787
          </a>
        </span> */}
      </div>

      <div className="">
      <a href="tel:+16317245300" target="_blank" className='text-center nav-link shadow-ss-1 btn-ss-primary border-0 rounded-5 border-0 text-white fs-ss-links px-4 py-2'>
      <TelephoneFill className='me-2'/> 631-724-5300
      </a>
      </div>
    </section>
    </>
  )
}

export default TopHeader