import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/gastric-sleeve/BannerImg.webp';
import Img1 from '../assets/images/gastric-sleeve/Img1.webp';
import MiddleBannerImage from '../assets/images/gastric-sleeve/MiddleBg.webp';
import MiddleBanner2 from '../components/common/MiddleBanner2';
import Video1 from '../assets/images/home/Video3.mp4';
import CommonBanner from '../components/CommonBanner';
import FAQsSection from '../components/FAQsSection';
import Bullets from '../components/common/Bullets';
import { TelephoneFill } from 'react-bootstrap-icons';
import { MetaTags } from 'react-meta-tags';

const GastricRevisions = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
      <MetaTags>
        <title> Gastric Bypass Surgery Revision: Long Island, NY </title> 
        <meta name="description" content="Gastric Bypass Surgery Revision - the solution you need to achieve your ideal weight and reclaim confidence. Our MD ensures your care is tailored to you." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Gastric Bypass Surgery Revision: Long Island, NY" />
        <meta property="og:description" content="Gastric Bypass Surgery Revision - the solution you need to achieve your ideal weight and reclaim confidence. Our MD ensures your care is tailored to you." />
        <meta property="og:url" content="https://northshoredigestivemedicinepc.com/" />
        <meta property="og:site_name" content="NSDM" />
        <meta property="og:image" content="https://northshoredigestivemedicinepc.com/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://northshoredigestivemedicinepc.com/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Gastric Bypass Surgery Revision - the solution you need to achieve your ideal weight and reclaim confidence. Our MD ensures your care is tailored to you." />
        <meta name="twitter:title" content="Gastric Bypass Surgery Revision: Long Island, NY" />
        <meta name="twitter:image" content="https://northshoredigestivemedicinepc.com/og.webp"/>
      </MetaTags>
    <Header/>

    <CommonBanner
    Title="Gastric Bypass Revisions"
    Image={BannerImg}
    />

<section className='pt-5 mt-lg-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary text-lg-center">
<div className="row justify-content-center">
    <div className="col-11 col-lg-12">
      <div className=''>
      <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
        North Shore Digestive Medicine
        </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2"> Specialized in Gastric Revisions  </h3>
        <div className='pt-4'/>
        <div className='fs-ss-16 fw-400 lh-para text-start'>
        Unfortunately, over time, weight gain can occur in many patients who have previously undergone gastric bypass or surgical sleeve gastrectomy procedures. Endoscopic revision is a non-surgical, minimally invasive procedure that helps patients to lose weight again.  

        <div className="row justify-content-start fw-600 pt-4">
        <div className="col-12 col-lg-6">
        <Bullets
        Title="Revision of VSG (surgical sleeve), gastric bypass, endoscopic sleeve"
        /> 
                <Bullets
        Title=" 12-15% total body weight loss "
        />        
        </div>
        
        <div className="col-12 col-lg-6">
        <Bullets
        Title="1-3-day recovery, with minimal side effects"
        /> 
                <Bullets
        Title="Non-surgical outpatient (leave the same day)"
        />        
        </div> 
        </div>
        </div>
        </div>
        
        <section className='pt-5'/>

        <div className="text-start">
        <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
      Types of Gastric Revisions 
        </h2>
        <div className='pt-2'/>
        <div className="row justify-content-start fw-600 pt-4">
        <div className="col-12 col-lg-10">
        <Bullets Title="Revision of prior surgical sleeve."/>       
        <Bullets Title="Revision of prior ESG by another provider."/>     
        <Bullets Title="Revision of prior gastric bypass - Transoral Outlet Reduction (ToRe)."/>  

        </div> 
        </div>
        </div>

        <div className="pt-4 pt-lg-5 text-start">
      <h3 className='fs-ss-28 fw-600 lh-sm'>
      Revision of Prior Surgical Sleeve
      </h3>
        <div className='pt-2'/>
        <div className='fs-ss-16 fw-400 lh-para text-start'>
        Weight regain occurs most often due to dilation of the stomach.  With endoscopic revision, your physician re-tightens your stomach to the original post-surgical anatomy.  Moreover, this will lead to weight loss by causing early satiety and, as a result, reduced oral intake.   
        </div>
        </div>

        <div className="pt-4 pt-lg-5 text-start">
      <h3 className='fs-ss-28 fw-600 lh-sm'>
      Revision of Prior ESG by Another Provider
      </h3>
        <div className='pt-2'/>
        <div className='fs-ss-16 fw-400 lh-para text-start'>
        If you've undergone gastric sleeve surgery elsewhere but have not achieved your desired weight goals or have experienced weight regain, North Shore Digestive Medicine offers a revision procedure to address these concerns. Our experienced team specializes in bariatric revisions, helping patients restart their weight loss journey and overcome obstacles. 
        </div>
        </div>

        <div className="pt-4 pt-lg-5 text-start">
      <h3 className='fs-ss-28 fw-600 lh-sm'>
      Revision of Gastric Bypass
      </h3>
        <div className='pt-2'/>
        <div className='fs-ss-16 fw-400 lh-para text-start'>
        Weight regain often occurs due to (a) dilation of the pouch, (b) dilation of the outlet, or (c) dilation of the pouch and outlet. Transoral outlet reduction (ToRe) is an endoscopic procedure that reduces the size of the pouch (if needed), the outlet, or the pouch and outlet (depending on each patient’s need) to the original post-bypass anatomy. This will help you feel full sooner and lose weight.  
        </div>
        </div>
      </div>
    

      </div>
      <div className="row justify-content-center text-center pt-5 mt-lg-4 bg-blue-pattern2">
        <div className="col-11 col-lg-7 bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black">
          <video className='w-100 rounded-3' src={Video1} autoPlay={true} loop="true" muted controls />
        </div>
</div>
</section>

    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className={`container-fluid bg-middle-banner2 py-5`} style={{backgroundImage:`url(${MiddleBannerImage})`}}>
      <div className="row justify-content-center">
        <div className="col-11 col-lg-6 py-md-4 text-center text-lg-start">
          <div className="" />
          <h2 className='fw-700 text-white fs-ss-middle-banner2 text-uppercase lh-sm'>
            Gastric Revisions <br/>to Get You Back <br/>on Track!
          </h2>
          <div id='btn' className="pt-4 pt-lg-5">
          <a href="tel:+16317245300" className='text-decoration-none py-2 fw-500 btn-middle-banner2 rounded-5 fs-ss-18 px-4 border-white border-1 border'>
         <TelephoneFill className='me-2'/> 631-724-5300
          </a>          
          </div>
        </div>
        <div className="col-4 col-lg-4"/>        
      </div>
    </section>

<section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-evenly align-items-center gy-4">
      <div className="col-11 col-lg-4 d-none d-lg-block ">
        <img className='w-100' src={Img1} alt="" />        
        </div>
        <div className="col-11 col-lg-6">
          <div className=''>
            <h2 className='fs-ss-32 fw-600 lh-sm text-uppercase'>
            Gastric Revisions at North Shore Digestive Medicine
            </h2>
            <h3 className="text-ss-primary fw-500 text-capitalize fs-ss-22 lh-sm pt-2">  What You Need to Know </h3>
            <div className='pt-4'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Our areas of expertise are revisions of prior gastric bypasses and gastric sleeve surgeries. We provide non-surgical procedures to treat weight gain after a prior sleeve or bypass surgery.  
            </div>
          </div>
        </div>
        <div className="col-10 col-md-7 col-lg-4 d-block d-lg-none">
        <img className='w-100' src={Img1} alt="" />        
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11 pb-md-0 pb-3">
          <FAQsSection
          Title="How long is the recovery period after a gastric revision? "
          Para="After undergoing a gastric revision, patients may experience discomfort, swelling, or mild pain, which are common during the initial recovery period. These symptoms are typically manageable with pain medications prescribed by your physician. Generally, most patients are able to resume their normal activities within 1 to 3 days post-procedure."
          />

          <FAQsSection
          Title="What are the benefits of gastric revision? "
          Para="The benefits of gastric revision include significant weight loss, minimally invasive techniques, outpatient convenience, and a brief recovery period."
          />

          <FAQsSection
          Title="Is the gastric revision procedure performed on an outpatient basis?"
          Para={<>
          At North Shore Digestive Medicine, gastric sleeve revision procedures are typically performed as outpatient procedures at our endoscopy suite.  
        <div className='py-1'/>           
          Operating as an outpatient has several advantages, such as: 
        <div className='py-1'/>           
        <ul className=''>
        <li> Patients can recover in familiar surroundings and with family support in the comfort of their own homes.</li>  
        <div className='py-1'/>           
        <li> Additionally, outpatient procedures lower the risk of hospital-acquired illnesses, allowing patients to return to their regular lives sooner. </li> 
        <div className='py-1'/>           
        <li> It reduces medical costs and makes access to the patient easier. </li>
        </ul>    
          </>}
          />
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default GastricRevisions;
