import React,{useEffect,useState} from "react"
import {Link} from "react-router-dom"
import axios from 'axios'
import moment from 'moment'
import { Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { ChevronDoubleRight, ChevronRight } from "react-bootstrap-icons"

const MainBlogs = () =>{

    const [blogs, setBlogs] = React.useState([])
    
    const [contentLoading, setContentLoading] = React.useState(true)
    const [isReadMoreshown, setReadMoreShown] = useState(false)
    const toggleBtn = () => {
        setReadMoreShown(prevState => !prevState)
    }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/blogs/website`).then(res => {
            if(res.data.blogs.length > 0){
                setBlogs(res.data.blogs)
            }else{
                setBlogs([])
            }
        }).catch(err => {
            console.log(err)
        }).finally (() => {
            setContentLoading(false)
        })
    },[])

    return (
    <>
    <section id="website-responsive" className="container-fluid py-4 font-pri">
        <div className="row justify-content-center gy-5">
        {contentLoading ?
        <div className='col-md-12 col-sm-12 text-center'>
            <Spinner animation="border" variant="dark" />
        </div>
        :
        <>
        {blogs.length > 0 ?
        <>
            {blogs.map((blog, index) => {
                return (
                <div className="col-8 col-md-3 shadow px-0 mx-4" style={{borderRadius:"22px"}}>
                    <div className="text-center image-box" 
                    style={{borderTopRightRadius:"20px", borderTopLeftRadius:"15px"}}>
                        <Link className="" to={`/blog/${blog?.slug}`}>
                            <img className='w-100 h-250' src={blog?.featured_image} alt={blog?.title} />
                        </Link>
                    </div>
                    
                    <div className='p-3 py-4'>
                        <small className='blog-date text-zk-primary col-sec'>Date: 
                            {/* July 27, 2023 in moment */}
                            &nbsp; {moment(blog.created_at).format('MMMM DD, YYYY')}
                        </small>
                        <div className="fs-ss-18 pt-2">
                        <Link className="text-black text-decoration-none" to={`/blog/${blog?.slug}`}>
                            <div style={{fontWeight:'600'}}>
                            Lorem ipsum dolor sit amet                             
                            </div>
                        </Link>
                        </div>
                        <div className="fs-ss-14 fw-500 pt-2">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam cum expedita nisi officiis esse a eligendi, non voluptas maiores architecto?
                        </div>
                    <div className='fs-ss-14 pt-3'>
                        <button className=' border-0 bg-white ps-0'>
                        <Link className="text-decoration-none fw-600 text-ss-primary" to={`/blog/${blog?.slug}`}>
                        Read More <ChevronDoubleRight className=""/>
                        </Link>
                        </button>
                    </div>
                    </div>
                </div>
                
            )
                })}
            </>
        :
        <div className='col-md-12 col-sm-12 text-center'>
            <div className='fs-zk-22 text-dark text-montserrat mb-2' style={{fontWeight:"600"}}>No Blogs Found</div>
        </div>
        }
        
        </>
        }
        </div>
    </section>
    </>
    )
}

export default MainBlogs;