import React from 'react'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import Icon1 from '../assets/images/Envelope.png'
import { Helmet } from 'react-helmet'
import { EnvelopeFill } from 'react-bootstrap-icons'

const ThankYou = () => {
    return (
        <>
        <Helmet>
        <meta name="robots" content="noindex"/>
          <title> Thank You - North Shore Digestive Medicine, PC </title>
        </Helmet>
        <Header/>
        <section id='website-responsive' className="container-fluid py-md-5 my-5">
            <div className="row justify-content-center">
                <div className="col-11 col-lg-7 bg-light text-center text-black py-5 px-4">
                    <div className="pb-md-4">
                    <div className="fs-ss-68 text-ss-primary pb-4">
                    <img className='w-25 px-md-5' src={Icon1} alt="" />        
                    </div>
                    <div className='px-lg-5'>
                    <div className="fs-ss-30 fw-700">
                    Thank You!
                    </div>
                    <div className="fs-ss-16 fw-400 lh-para">
                    Thank you for your inquiry with <span className='fw-600'> North Shore Digestive Medicine, PC. </span> <br className="" /> We will contact you shortly! <br /> Please call our office at <a href="tel:+16317245300" className='text-decoration-none text-ss-secondary fw-600' > 631-724-5300 </a> if you have any questions
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <Footer/>
        </>
    )
}

export default ThankYou