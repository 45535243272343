
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImg from "../assets/images/Blogs.webp";
import MainBlogs from '../components/blogs/MainBlogs';
import CommonBanner from '../components/CommonBanner';

const Blogs = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
            <Helmet>
              <title>
              Blogs - North Shore Digestive Medicine
              </title>  
            </Helmet>
    <Header/>
    <CommonBanner
    Title="Blogs"
    Image={BannerImg}
    />
    <div className="py-5"/>
    <MainBlogs/>
    <div className="py-5"/>
    <Footer/>
    </>
  );
}

export default Blogs;
